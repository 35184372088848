import React from "react";
import Layout from '../../../components/layout';

const DocsPage = props => (
  <Layout pageTitle="Docs">
    <main>

    </main>
  </Layout>
);

export default DocsPage;
